import React, { useState, useEffect, ReactNode } from 'react';

const SoundButton = ({
  frequencies,
  onMouseOver = () => null,
  children,
  ...rest
}: {
  frequencies: number[];
  onMouseOver?: () => void;
  children: ReactNode;
}) => {
  const [play, setPlay] = useState(false);

  useEffect(() => {
    const context = play ? new AudioContext() : null;
    const o: OscillatorNode[] = [];
    if (play && context) {
      frequencies.forEach(f => {
        const os = context.createOscillator();
        const g = context.createGain();
        os.type = 'triangle';
        os.frequency.value = f;
        os.connect(g);
        g.gain.value = 0.25;
        g.connect(context.destination);
        os.start();
        o.push(os);
      });
    }
    return () => {
      o.forEach(o => o && o.stop());
      context && context.close();
    };
  }, [play, frequencies]);

  const handleMouseOver = () => {
    setPlay(true);
    onMouseOver();
  };

  return (
    <button
      onMouseOver={handleMouseOver}
      onMouseOut={() => setPlay(false)}
      {...rest}
    >
      {children}
    </button>
  );
};

export default SoundButton;

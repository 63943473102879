import React, { useRef, useEffect, ReactElement } from 'react';
import { FingerCoordinate } from './types';

const FingerBoard = ({
  fingers,
}: {
  fingers: FingerCoordinate[];
}): ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      if (context) {
        clear(context);
        drawStrings(context);

        fingers.forEach(point => drawPoint(point, context));
      }
    }
  }, [fingers]);

  return <canvas ref={canvasRef} width={30 * 3 + 40} height={800} />;
};

export default FingerBoard;

const clear = (context: CanvasRenderingContext2D) => {
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);
};

const drawStrings = (context: CanvasRenderingContext2D) => {
  clear(context);
  context.beginPath();
  for (let i = 1; i <= 4; ++i) {
    // const x = (context.canvas.width / 5) * (i + 1);
    // draw strings
    const x = i * 30 - 10;
    context.moveTo(x, 10);
    context.lineTo(x, context.canvas.height - 10);

    // draw the beginning
    context.moveTo(20, 10);
    context.lineTo(30 * 4 - 10, 10);
    // draw the end
    context.moveTo(20, context.canvas.height - 10);
    context.lineTo(30 * 4 - 10, context.canvas.height - 10);
  }

  context.strokeStyle = '#111';
  context.lineWidth = 2;
  context.stroke();

  // draw frets
  context.beginPath();

  for (let i = 1; i <= 200; ++i) {
    const yy = 1 - 2 ** (-i / 12);

    const y = (context.canvas.height - 20) * yy + 10;

    context.moveTo(20, y);
    context.lineTo(30 * 4 - 10, y);
  }

  context.strokeStyle = 'blue';
  context.lineWidth = 1;
  context.stroke();
};

const drawPoint = (
  [x, y, tone]: FingerCoordinate,
  context: CanvasRenderingContext2D,
) => {
  const xx = x * 30 + 20;
  const yy = fretCoordinate(y, 10, stringLength(context) + 10);
  const color = ['red', 'orange', 'pink', 'salmon'][tone];
  context.beginPath();
  context.arc(xx, yy, 9, 0, 2 * Math.PI);
  context.strokeStyle = color;
  context.lineWidth = 2;
  context.stroke();

  if (y !== 0) {
    context.fillStyle = color;
    context.fill();
  }
};

const stringLength = (context: CanvasRenderingContext2D) =>
  context.canvas.height - 20;

const fretCoordinate = (
  fret: number,
  stringBeginning: number,
  stringEnd: number,
): number => {
  const yy = 1 - 2 ** (-fret / 12);

  return (stringEnd - stringBeginning) * yy + 10;
};

import React, {
  useState,
  useEffect,
  ReactElement,
  FormEvent,
  ChangeEvent,
} from 'react';
import { chord2fingering, chords, notes, accidentals } from './chords';

const availableChords = Object.keys(chords);
const availableNotes = Object.keys(notes);
const availableAccidentals = Object.keys(accidentals);

const ChordForm = ({
  onSubmit,
}: {
  onSubmit: (chord: string) => void;
}): ReactElement => {
  const [chord, setChord] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    try {
      chord2fingering(chord);
      setError('');
    } catch (e) {
      setError(e.message);
    }
  }, [chord]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setChord('');
    onSubmit(chord);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setChord(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      {chord && error && <div>{error}</div>}
      <input value={chord} onChange={handleChange} />
      <input type="submit" value="submit" disabled={!chord || !!error} />
      <div>
        {availableNotes.map(note => (
          <a onClick={() => setChord(note)} key={note}>
            {note}
          </a>
        ))}
      </div>
      <div>
        {availableAccidentals.map(acc => (
          <a onClick={() => setChord(value => value + acc)} key={acc}>
            {acc}
          </a>
        ))}
      </div>
      <div>
        {availableChords.map(chord => (
          <a onClick={() => setChord(value => value + chord)} key={chord}>
            {chord}
          </a>
        ))}
      </div>
    </form>
  );
};

export default ChordForm;

import { FingerCoordinate } from './types';

export const chords: { [name: string]: number[] } = {
  maj: [0, 4, 7],
  min: [0, 3, 7],
  '+': [0, 4, 8],
  '0': [0, 3, 6, 9],
  '6': [0, 4, 7, 9],
  '7': [0, 4, 7, 10],
  maj7: [0, 4, 7, 11],
  min7: [0, 3, 7, 10],
};

export const notes: { [name: string]: number } = {
  c: 0,
  d: 2,
  e: 4,
  f: 5,
  g: 7,
  a: 9,
  b: 10,
  h: 11,
};

export const accidentals = {
  '#': 1,
  b: 11,
};

type NumberChord = {
  base: number;
  tones: number[];
};

export const chord2fingering = (chord: string): FingerCoordinate[] => {
  return numbers2fingering(chord2numbers(chord));
};

const numbers2fingering = ({
  base,
  tones,
}: NumberChord): FingerCoordinate[] => {
  const strings = [7, 2, 9, 4];
  // g = 7
  // d = 2
  // a = 9
  // e = 4

  const points: FingerCoordinate[] = [];

  strings.forEach((str, i) => {
    tones.forEach((tone, j) => {
      points.push([i, (tone + base - str + 12) % 12, j]);
    });
  });

  return points;
};

const chord2numbers = (chord: string): NumberChord => {
  const separator = ['#', 'b'].includes(chord[1]) ? 2 : 1;
  const note = chord.slice(0, separator);
  const chordType = chord.slice(separator);

  if (!(chordType in chords)) {
    throw new Error("chord type doesn't exist");
  }

  return {
    base: note2number(note),
    tones: chords[chordType],
  };
};

export const isChord = (chord: string): boolean => {
  try {
    chord2numbers(chord);
    return true;
  } catch {
    return false;
  }
};

function note2number(note: string): number {
  if (!Object.keys(notes).includes(note[0]))
    throw new Error('invalid note name (c, d, e, f, g, a, b, h)');

  if (note.length === 1) return notes[note];

  const accidental2number = (accidental: string): 1 | 11 => {
    if (accidental === '#') return 1;
    if (accidental === 'b') return 11;
    throw new Error('invalid accidental (#, b)');
  };

  if (note.length === 2)
    return notes[note[0]] + (accidental2number(note[1]) % 12);

  throw new Error('invalid note name');
}

export const chord2frequencies = (chord: string): number[] => {
  const { base, tones } = chord2numbers(chord);

  return [
    tone2frequency(base) / 2,
    ...tones.map(tone => tone2frequency((base + tone) % 12)),
  ];
};

const tone2frequency = (tone: number): number => {
  tone = tone >= 7 ? tone - 12 : tone;
  // a === 9 === 440
  const c = 440 * 2 ** (-9 / 12);

  return c * 2 ** (tone / 12);
};

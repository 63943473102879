import React, { useState, ReactElement } from 'react';
import FingerBoard from './FingerBoard';
import ChordForm from './ChordForm';
import { chord2fingering, chord2frequencies } from './chords';
import SoundButton from './SoundButton';

type ChordSelected = {
  chord: string;
  selected: boolean;
};

const initialChords = [
  { chord: 'dmin7', selected: true },
  { chord: 'g7', selected: false },
  { chord: 'cmaj7', selected: false },
];

function App(): ReactElement {
  const [chords, setChords] = useState(initialChords as ChordSelected[]);

  const selectedChord = chords.find(({ selected }) => selected);
  const fingers = selectedChord ? chord2fingering(selectedChord.chord) : [];

  const handleRemoveChord = (i: number) => {
    setChords(chords => [...chords.slice(0, i), ...chords.slice(i + 1)]);
  };

  const handleAddChord = (chord: string) => {
    setChords(chords => [
      ...chords.map(({ chord }) => ({ chord, selected: false })),
      { chord, selected: true },
    ]);
  };

  const handleSelectChord = (i: number) => {
    setChords(chords.map((chord, j) => ({ ...chord, selected: i === j })));
  };

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <div style={{ textAlign: 'center', fontSize: '2em' }}>
          {chords.find(({ selected }) => selected)?.chord ?? null}
        </div>
        <FingerBoard fingers={fingers} />
      </div>
      <div>
        <ChordForm onSubmit={handleAddChord} />
        <ul>
          {chords.map(({ chord }, i) => (
            <li key={i}>
              <SoundButton
                frequencies={chord2frequencies(chord)}
                onMouseOver={() => handleSelectChord(i)}
              >
                {chord}
              </SoundButton>
              <button onClick={() => handleRemoveChord(i)}>x</button>
              {/*i > 0 && <button onClick={() => handleUp(i)}>up</button>}
              {i < chords.length - 1 && (
                <button onClick={() => handleDown(i)}>down</button>
              )*/}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
